/* o6n.css module Colors 1.0 */
/* BACKGROUND COLORS */
.o6n-amber,.o6n-amber-hover:hover{background-color:#ffc107!important}
.o6n-aqua,.o6n-aqua-hover:hover{background-color:#00ffff!important}
.o6n-black,.o6n-black-hover:hover{background-color:#000!important}
.o6n-blue,.o6n-blue-hover:hover{background-color:#2196F3!important}
.o6n-brown,.o6n-brown-hover:hover{background-color:#795548!important}
.o6n-cyan,.o6n-cyan-hover:hover{background-color:#00bcd4!important}
.o6n-green,.o6n-green-hover:hover{background-color:#006800!important}
.o6n-grey,.o6n-grey-hover:hover,.o6n-gray,.o6n-gray-hover:hover{background-color:#9e9e9e!important}
.o6n-indigo,.o6n-indigo-hover:hover{background-color:#3f51b5!important}
.o6n-khaki,.o6n-khaki-hover:hover{background-color:#f0e68c!important}
.o6n-lime,.o6n-lime-hover:hover{background-color:#cddc39!important}
.o6n-orange,.o6n-orange-hover:hover{background-color:#ff9800!important}
.o6n-pink,.o6n-pink-hover:hover{background-color:#e91e63!important}
.o6n-purple,.o6n-purple-hover:hover{background-color:#9c27b0!important}
.o6n-red,.o6n-red-hover:hover{background-color:#f44336!important}
.o6n-sand,.o6n-sand-hover:hover{background-color:#fdf5e6!important}
.o6n-teal,.o6n-teal-hover:hover{background-color:#009688!important}
.o6n-white,.o6n-white-hover:hover{background-color:#fff!important}
.o6n-yellow,.o6n-yellow-hover:hover{background-color:#ffeb3b!important}
/* TEXT COLORS */
.o6n-text-amber,.o6n-text-amber-hover:hover{color:#ffc107!important}
.o6n-text-aqua,.o6n-text-aqua-hover:hover{color:#00ffff!important}
.o6n-text-black,.o6n-text-black-hover:hover{color:#000!important}
.o6n-text-blue,.o6n-text-blue-hover:hover{color:#2196F3!important}
.o6n-text-brown,.o6n-text-brown-hover:hover{color:#795548!important}
.o6n-text-cyan,.o6n-text-cyan-hover:hover{color:#00bcd4!important}
.o6n-text-green,.o6n-text-green-hover:hover{color:#006800!important}
.o6n-text-grey,.o6n-text-grey-hover:hover,.o6n-text-gray,.o6n-text-gray-hover:hover{color:#9e9e9e!important}
.o6n-text-indigo,.o6n-text-indigo-hover:hover{color:#3f51b5!important}
.o6n-text-khaki,.o6n-text-khaki-hover:hover{color:#f0e68c!important}
.o6n-text-lime,.o6n-text-lime-hover:hover{color:#cddc39!important}
.o6n-text-orange,.o6n-text-orange-hover:hover{color:#ff9800!important}
.o6n-text-pink,.o6n-text-pink-hover:hover{color:#e91e63!important}
.o6n-text-purple,.o6n-text-purple-hover:hover{color:#9c27b0!important}
.o6n-text-red,.o6n-text-red-hover:hover{color:#f44336!important}
.o6n-text-sand,.o6n-text-sand-hover:hover{color:#fdf5e6!important}
.o6n-text-teal,.o6n-text-teal-hover:hover{color:#009688!important}
.o6n-text-white,.o6n-text-white-hover:hover{color:#fff!important}
.o6n-text-yellow,.o6n-text-yellow-hover:hover{color:#ffeb3b!important}
/* BORDERS COLORS */
.o6n-border-amber,.o6n-border-amber-hover:hover{border:1px solid #ffc107!important}
.o6n-border-aqua,.o6n-border-aqua-hover:hover{border:1px solid #00ffff!important}
.o6n-border-black,.o6n-border-black-hover:hover{border:1px solid #000!important}
.o6n-border-blue,.o6n-border-blue-hover:hover{border:1px solid #2196F3!important}
.o6n-border-brown,.o6n-border-brown-hover:hover{border:1px solid #795548!important}
.o6n-border-cyan,.o6n-border-cyan-hover:hover{border:1px solid #00bcd4!important}
.o6n-border-green,.o6n-border-green-hover:hover{border:1px solid #006800!important}
.o6n-border-grey,.o6n-border-grey-hover:hover,.o6n-border-gray,.o6n-border-gray-hover:hover{border:1px solid #9e9e9e!important}
.o6n-border-indigo,.o6n-border-indigo-hover:hover{border:1px solid #3f51b5!important}
.o6n-border-khaki,.o6n-border-khaki-hover:hover{border:1px solid #f0e68c!important}
.o6n-border-lime,.o6n-border-lime-hover:hover{border:1px solid #cddc39!important}
.o6n-border-orange,.o6n-border-orange-hover:hover{border:1px solid #ff9800!important}
.o6n-border-pink,.o6n-border-pink-hover:hover{border:1px solid #e91e63!important}
.o6n-border-purple,.o6n-border-purple-hover:hover{border:1px solid #9c27b0!important}
.o6n-border-red,.o6n-border-red-hover:hover{border:1px solid #f44336!important}
.o6n-border-sand,.o6n-border-sand-hover:hover{border:1px solid #fdf5e6!important}
.o6n-border-teal,.o6n-border-teal-hover:hover{border:1px solid #009688!important}
.o6n-border-white,.o6n-border-white-hover:hover{border:1px solid #fff!important}
.o6n-border-yellow,.o6n-border-yellow-hover:hover{border:1px solid #ffeb3b!important}

/* SOCIAL */
/* BACKGROUND COLORS */
.o6n-airbnb,.o6n-airbnb-hover:hover{background-color:#ff5a5f!important;}
.o6n-alexa,.o6n-alexa-hover:hover{background-color:#00a7ce!important;}
.o6n-amazon,.o6n-amazon-hover:hover{background-color:#ff9900!important;}
.o6n-android,.o6n-android-hover:hover{background-color:#a4c639!important;}
.o6n-angieslist,.o6n-angieslist-hover:hover{background-color:#29a036!important;}
.o6n-apple,.o6n-apple-hover:hover{background-color:#a6b1b7!important;}
.o6n-behance,.o6n-behance-hover:hover{background-color:#131418!important;}
.o6n-blogger,.o6n-blogger-hover:hover{background-color:#f57d00!important;}
.o6n-clarity,.o6n-clarity-hover:hover{background-color:#61bed9!important;}
.o6n-deviantart,.o6n-deviantart-hover:hover{background-color:#05cc47!important;}
.o6n-discord,.o6n-discord-hover:hover{background-color:#7289da!important;}
.o6n-dribbble,.o6n-dribbble-hover:hover{background-color:#ea4c89!important;}
.o6n-dropbox,.o6n-dropbox-hover:hover{background-color:#007ee5!important;}
.o6n-facebook,.o6n-facebook-hover:hover{background-color:#1877f2!important;}
.o6n-fivehundredpx,.o6n-fivehundredpx-hover:hover{background-color:#0099e5!important;}
.o6n-flickr,.o6n-flickr-hover:hover{background-color:#f40083!important;}
.o6n-foursquare,.o6n-foursquare-hover:hover{background-color:#073282!important;}
.o6n-glassdoor,.o6n-glassdoor-hover:hover{background-color:#0caa41!important;}
.o6n-google,.o6n-google-hover:hover{background-color:#4285f4!important;}
.o6n-hackernews,.o6n-hackernews-hover:hover{background-color:#ff6600!important;}
.o6n-homeadvisor,.o6n-homeadvisor-hover:hover{background-color:#f89000!important;}
.o6n-houzz,.o6n-houzz-hover:hover{background-color:#4dbc15!important;}
.o6n-instagram,.o6n-instagram-hover:hover{background-color:#e4405f!important;}
.o6n-line,.o6n-line-hover:hover{background-color:#00c300!important;}
.o6n-linkedin,.o6n-linkedin-hover:hover{background-color:#0077b5!important;}
.o6n-listly,.o6n-listly-hover:hover{background-color:#df6d46!important;}
.o6n-mastodon,.o6n-mastodon-hover:hover{background-color:#2b90d9!important;}
.o6n-medium,.o6n-medium-hover:hover{background-color:#02b875!important;}
.o6n-messenger,.o6n-messenger-hover:hover{background-color:#0084ff!important;}
.o6n-microsoft,.o6n-microsoft-hover:hover{background-color:#00A4EF!important;}
.o6n-periscope,.o6n-periscope-hover:hover{background-color:#40a4c4!important;}
.o6n-pinterest,.o6n-pinterest-hover:hover{background-color:#bd081c!important;}
.o6n-pocket,.o6n-pocket-hover:hover{background-color:#ee4056!important;}
.o6n-producthunt,.o6n-producthunt-hover:hover{background-color:#da552f!important;}
.o6n-quora,.o6n-quora-hover:hover{background-color:#aa2200!important;}
.o6n-reddit,.o6n-reddit-hover:hover{background-color:#ff5700!important;}
.o6n-skype,.o6n-skype-hover:hover{background-color:#0078d7!important;}
.o6n-slack,.o6n-slack-hover:hover{background-color:#3aaf85!important;}
.o6n-slideshare,.o6n-slideshare-hover:hover{background-color:#0077b5!important;}
.o6n-snapchat,.o6n-snapchat-hover:hover{background-color:#fffc00!important;}
.o6n-soundcloud,.o6n-soundcloud-hover:hover{background-color:#ff3300!important;}
.o6n-spotify,.o6n-spotify-hover:hover{background-color:#1ed760!important;}
.o6n-steam,.o6n-steam-hover:hover{background-color:#171a21!important;}
.o6n-stumbleupon,.o6n-stumbleupon-hover:hover{background-color:#eb4924!important;}
.o6n-swarm,.o6n-swarm-hover:hover{background-color:#ffa633!important;}
.o6n-telegram,.o6n-telegram-hover:hover{background-color:#0088cc!important;}
.o6n-tiktok,.o6n-tiktok-hover:hover{background-color:#010101!important;}
.o6n-tumblr,.o6n-tumblr-hover:hover{background-color:#2c4762!important;}
.o6n-twitch,.o6n-twitch-hover:hover{background-color:#9146ff!important;}
.o6n-twitter,.o6n-twitter-hover:hover{background-color:#55acee!important;}
.o6n-vimeo,.o6n-vimeo-hover:hover{background-color:#1ab7ea!important;}
.o6n-vine,.o6n-vine-hover:hover{background-color:#00b489!important;}
.o6n-vk,.o6n-vk-hover:hover{background-color:#4c75a3!important;}
.o6n-wechat,.o6n-wechat-hover:hover{background-color:#09b83e!important;}
.o6n-weibo,.o6n-weibo-hover:hover{background-color:#df2029!important;}
.o6n-whatsapp,.o6n-whatsapp-hover:hover{background-color:#25d366!important;}
.o6n-wordpress,.o6n-wordpress-hover:hover{background-color:#21759b!important;}
.o6n-yahoo,.o6n-yahoo-hover:hover{background-color:#410093!important;}
.o6n-yelp,.o6n-yelp-hover:hover{background-color:#af0606!important;}
.o6n-youtube,.o6n-youtube-hover:hover{background-color:#ff0000!important;}
/* TEXT COLORS */
.o6n-text-airbnb,.o6n-text-airbnb-hover:hover{color:#ff5a5f!important;}
.o6n-text-alexa,.o6n-text-alexa-hover:hover{color:#00a7ce!important;}
.o6n-text-amazon,.o6n-text-amazon-hover:hover{color:#ff9900!important;}
.o6n-text-android,.o6n-text-android-hover:hover{color:#a4c639!important;}
.o6n-text-angieslist,.o6n-text-angieslist-hover:hover{color:#29a036!important;}
.o6n-text-apple,.o6n-text-apple-hover:hover{color:#a6b1b7!important;}
.o6n-text-behance,.o6n-text-behance-hover:hover{color:#131418!important;}
.o6n-text-blogger,.o6n-text-blogger-hover:hover{color:#f57d00!important;}
.o6n-text-clarity,.o6n-text-clarity-hover:hover{color:#61bed9!important;}
.o6n-text-deviantart,.o6n-text-deviantart-hover:hover{color:#05cc47!important;}
.o6n-text-discord,.o6n-text-discord-hover:hover{color:#7289da!important;}
.o6n-text-dribbble,.o6n-text-dribbble-hover:hover{color:#ea4c89!important;}
.o6n-text-dropbox,.o6n-text-dropbox-hover:hover{color:#007ee5!important;}
.o6n-text-facebook,.o6n-text-facebook-hover:hover{color:#1877f2!important;}
.o6n-text-fivehundredpx,.o6n-text-fivehundredpx-hover:hover{color:#0099e5!important;}
.o6n-text-flickr,.o6n-text-flickr-hover:hover{color:#f40083!important;}
.o6n-text-foursquare,.o6n-text-foursquare-hover:hover{color:#073282!important;}
.o6n-text-glassdoor,.o6n-text-glassdoor-hover:hover{color:#0caa41!important;}
.o6n-text-google,.o6n-text-google-hover:hover{color:#4285f4!important;}
.o6n-text-hackernews,.o6n-text-hackernews-hover:hover{color:#ff6600!important;}
.o6n-text-homeadvisor,.o6n-text-homeadvisor-hover:hover{color:#f89000!important;}
.o6n-text-houzz,.o6n-text-houzz-hover:hover{color:#4dbc15!important;}
.o6n-text-instagram,.o6n-text-instagram-hover:hover{color:#e4405f!important;}
.o6n-text-line,.o6n-text-line-hover:hover{color:#00c300!important;}
.o6n-text-linkedin,.o6n-text-linkedin-hover:hover{color:#0077b5!important;}
.o6n-text-listly,.o6n-text-listly-hover:hover{color:#df6d46!important;}
.o6n-text-mastodon,.o6n-text-mastodon-hover:hover{color:#2b90d9!important;}
.o6n-text-medium,.o6n-text-medium-hover:hover{color:#02b875!important;}
.o6n-text-messenger,.o6n-text-messenger-hover:hover{color:#0084ff!important;}
.o6n-text-microsoft,.o6n-text-microsoft-hover:hover{color:#00A4EF!important;}
.o6n-text-periscope,.o6n-text-periscope-hover:hover{color:#40a4c4!important;}
.o6n-text-pinterest,.o6n-text-pinterest-hover:hover{color:#bd081c!important;}
.o6n-text-pocket,.o6n-text-pocket-hover:hover{color:#ee4056!important;}
.o6n-text-producthunt,.o6n-text-producthunt-hover:hover{color:#da552f!important;}
.o6n-text-quora,.o6n-text-quora-hover:hover{color:#aa2200!important;}
.o6n-text-reddit,.o6n-text-reddit-hover:hover{color:#ff5700!important;}
.o6n-text-skype,.o6n-text-skype-hover:hover{color:#0078d7!important;}
.o6n-text-slack,.o6n-text-slack-hover:hover{color:#3aaf85!important;}
.o6n-text-slideshare,.o6n-text-slideshare-hover:hover{color:#0077b5!important;}
.o6n-text-snapchat,.o6n-text-snapchat-hover:hover{color:#fffc00!important;}
.o6n-text-soundcloud,.o6n-text-soundcloud-hover:hover{color:#ff3300!important;}
.o6n-text-spotify,.o6n-text-spotify-hover:hover{color:#1ed760!important;}
.o6n-text-steam,.o6n-text-steam-hover:hover{color:#171a21!important;}
.o6n-text-stumbleupon,.o6n-text-stumbleupon-hover:hover{color:#eb4924!important;}
.o6n-text-swarm,.o6n-text-swarm-hover:hover{color:#ffa633!important;}
.o6n-text-telegram,.o6n-text-telegram-hover:hover{color:#0088cc!important;}
.o6n-text-tiktok,.o6n-text-tiktok-hover:hover{color:#010101!important;}
.o6n-text-tumblr,.o6n-text-tumblr-hover:hover{color:#2c4762!important;}
.o6n-text-twitch,.o6n-text-twitch-hover:hover{color:#9146ff!important;}
.o6n-text-twitter,.o6n-text-twitter-hover:hover{color:#55acee!important;}
.o6n-text-vimeo,.o6n-text-vimeo-hover:hover{color:#1ab7ea!important;}
.o6n-text-vine,.o6n-text-vine-hover:hover{color:#00b489!important;}
.o6n-text-vk,.o6n-text-vk-hover:hover{color:#4c75a3!important;}
.o6n-text-wechat,.o6n-text-wechat-hover:hover{color:#09b83e!important;}
.o6n-text-weibo,.o6n-text-weibo-hover:hover{color:#df2029!important;}
.o6n-text-weibo,.o6n-text-weibo-hover:hover{color:#df2029!important;}
,.o6n-border--hover:hover.o6n-text-whatsapp,.o6n-text-whatsapp-hover:hover{color:#25d366!important;}
.o6n-text-wordpress,.o6n-text-wordpress-hover:hover{color:#21759b!important;}
.o6n-text-yahoo,.o6n-text-yahoo-hover:hover{color:#410093!important;}
.o6n-text-yelp,.o6n-text-yelp-hover:hover{color:#af0606!important;}
.o6n-text-youtube,.o6n-text-youtube-hover:hover{color:#ff0000!important;}
/* BORDER COLORS */
.o6n-border-airbnb,.o6n-border-airbnb-hover:hover{border:1px solid #ff5a5f!important;}
.o6n-border-alexa,.o6n-border-alexa-hover:hover{border:1px solid #00a7ce!important;}
.o6n-border-amazon,.o6n-border-amazon-hover:hover{border:1px solid #ff9900!important;}
.o6n-border-android,.o6n-border-android-hover:hover{border:1px solid #a4c639!important;}
.o6n-border-angieslist,.o6n-border-angieslist-hover:hover{border:1px solid #29a036!important;}
.o6n-border-apple,.o6n-border-apple-hover:hover{border:1px solid #a6b1b7!important;}
.o6n-border-behance,.o6n-border-behance-hover:hover{border:1px solid #131418!important;}
.o6n-border-blogger,.o6n-border-blogger-hover:hover{border:1px solid #f57d00!important;}
.o6n-border-clarity,.o6n-border-clarity-hover:hover{border:1px solid #61bed9!important;}
.o6n-border-deviantart,.o6n-border-deviantart-hover:hover{border:1px solid #05cc47!important;}
.o6n-border-discord,.o6n-border-discord-hover:hover{border:1px solid #7289da!important;}
.o6n-border-dribbble,.o6n-border-dribbble-hover:hover{border:1px solid #ea4c89!important;}
.o6n-border-dropbox,.o6n-border-dropbox-hover:hover{border:1px solid #007ee5!important;}
.o6n-border-facebook,.o6n-border-facebook-hover:hover{border:1px solid #1877f2!important;}
.o6n-border-fivehundredpx,.o6n-border-fivehundredpx-hover:hover{border:1px solid #0099e5!important;}
.o6n-border-flickr,.o6n-border-flickr-hover:hover{border:1px solid #f40083!important;}
.o6n-border-foursquare,.o6n-border-foursquare-hover:hover{border:1px solid #073282!important;}
.o6n-border-glassdoor,.o6n-border-glassdoor-hover:hover{border:1px solid #0caa41!important;}
.o6n-border-google,.o6n-border-google-hover:hover{border:1px solid #4285f4!important;}
.o6n-border-hackernews,.o6n-border-hackernews-hover:hover{border:1px solid #ff6600!important;}
.o6n-border-homeadvisor,.o6n-border-homeadvisor-hover:hover{border:1px solid #f89000!important;}
.o6n-border-houzz,.o6n-border-houzz-hover:hover{border:1px solid #4dbc15!important;}
.o6n-border-instagram,.o6n-border-instagram-hover:hover{border:1px solid #e4405f!important;}
.o6n-border-line,.o6n-border-line-hover:hover{border:1px solid #00c300!important;}
.o6n-border-linkedin,.o6n-border-linkedin-hover:hover{border:1px solid #0077b5!important;}
.o6n-border-listly,.o6n-border-listly-hover:hover{border:1px solid #df6d46!important;}
.o6n-border-mastodon,.o6n-border-mastodon-hover:hover{border:1px solid #2b90d9!important;}
.o6n-border-medium,.o6n-border-medium-hover:hover{border:1px solid #02b875!important;}
.o6n-border-messenger,.o6n-border-messenger-hover:hover{border:1px solid #0084ff!important;}
.o6n-border-microsoft,.o6n-border-microsoft-hover:hover{border:1px solid #00A4EF!important;}
.o6n-border-periscope,.o6n-border-periscope-hover:hover{border:1px solid #40a4c4!important;}
.o6n-border-pinterest,.o6n-border-pinterest-hover:hover{border:1px solid #bd081c!important;}
.o6n-border-pocket,.o6n-border-pocket-hover:hover{border:1px solid #ee4056!important;}
.o6n-border-producthunt,.o6n-border-producthunt-hover:hover{border:1px solid #da552f!important;}
.o6n-border-quora,.o6n-border-quora-hover:hover{border:1px solid #aa2200!important;}
.o6n-border-reddit,.o6n-border-reddit-hover:hover{border:1px solid #ff5700!important;}
.o6n-border-skype,.o6n-border-skype-hover:hover{border:1px solid #0078d7!important;}
.o6n-border-slack,.o6n-border-slack-hover:hover{border:1px solid #3aaf85!important;}
.o6n-border-slideshare,.o6n-border-slideshare-hover:hover{border:1px solid #0077b5!important;}
.o6n-border-snapchat,.o6n-border-snapchat-hover:hover{border:1px solid #fffc00!important;}
.o6n-border-soundcloud,.o6n-border-soundcloud-hover:hover{border:1px solid #ff3300!important;}
.o6n-border-spotify,.o6n-border-spotify-hover:hover{border:1px solid #1ed760!important;}
.o6n-border-steam,.o6n-border-steam-hover:hover{border:1px solid #171a21!important;}
.o6n-border-stumbleupon,.o6n-border-stumbleupon-hover:hover{border:1px solid #eb4924!important;}
.o6n-border-swarm,.o6n-border-swarm-hover:hover{border:1px solid #ffa633!important;}
.o6n-border-telegram,.o6n-border-telegram-hover:hover{border:1px solid #0088cc!important;}
.o6n-border-tiktok,.o6n-border-tiktok-hover:hover{border:1px solid #010101!important;}
.o6n-border-tumblr,.o6n-border-tumblr-hover:hover{border:1px solid #2c4762!important;}
.o6n-border-twitch,.o6n-border-twitch-hover:hover{border:1px solid #9146ff!important;}
.o6n-border-twitter,.o6n-border-twitter-hover:hover{border:1px solid #55acee!important;}
.o6n-border-vimeo,.o6n-border-vimeo-hover:hover{border:1px solid #1ab7ea!important;}
.o6n-border-vine,.o6n-border-vine-hover:hover{border:1px solid #00b489!important;}
.o6n-border-vk,.o6n-border-vk-hover:hover{border:1px solid #4c75a3!important;}
.o6n-border-wechat,.o6n-border-wechat-hover:hover{border:1px solid #09b83e!important;}
.o6n-border-weibo,.o6n-border-weibo-hover:hover{border:1px solid #df2029!important;}
.o6n-border-whatsapp,.o6n-border-whatsapp-hover:hover{border:1px solid #25d366!important;}
.o6n-border-wordpress,.o6n-border-wordpress-hover:hover{border:1px solid #21759b!important;}
.o6n-border-yahoo,.o6n-border-yahoo-hover:hover{border:1px solid #410093!important;}
.o6n-border-yelp,.o6n-border-yelp-hover:hover{border:1px solid #af0606!important;}
.o6n-border-youtube,.o6n-border-youtube-hover:hover{border:1px solid #ff0000!important;}
